var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", title: "", fixed: "" }, slot: "header" },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c("add-index", {
        staticClass: "special",
        attrs: {
          personData: _vm.personData,
          type: { type: _vm.type, name: _vm.name },
          clientLength: _vm.$route.params.clientLength,
        },
        on: {
          passData: _vm.passData,
          pass: _vm.passName,
          passisTrue: _vm.passisTrue,
          passid: _vm.passid,
          agent: _vm.agent,
          delagent: _vm.delagent,
        },
      }),
      _c("div", { staticClass: "bottom-btn" }, [
        _c("span", { staticClass: "cancel-btn", on: { click: _vm.del } }, [
          _vm._v("删除"),
        ]),
        _c("span", { staticClass: "save-btn", on: { click: _vm.save } }, [
          _vm._v("保存"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }