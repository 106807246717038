<template>
  <fb-page>
    <fb-header slot="header" :title="caseData.reasonRelation" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="$router.back()"
        size="small"
        type="primary"
      >
      </fb-button>
    </fb-header>
    <div class="anjian-content">
      <div
        style="display: flex;align-items: center;justify-content: center;"
        v-if="loading"
      >
        <van-loading />
      </div>
      <div class="border-bottom">
        <p>案由</p>
        <p>{{ caseData.reasonRelation }}</p>
      </div>
      <div class="border-bottom">
        <p>代理日期</p>
        <p>
          {{ acceptDate }}
          <i class="iconfont icon-calendar" @click="show2 = !show2"></i>
        </p>
      </div>
      <div class="border-bottom">
        <p>代理身份</p>
        <p>{{ caseData.principal }}</p>
      </div>
      <div class="border-bottom">
        <p>代理权限</p>
        <p @click="select">{{ clientType }}</p>
      </div>
      <div class="border-bottom">
        <p>案号</p>
        <input
          type="text"
          class="anhaoinput"
          style="line-height: 20px;"
          v-model="caseData.caseNo"
          placeholder="暂无，点击输入"
        />
      </div>
      <div class="border-bottom">
        <p>密码</p>
        <p class="passContent">
          <input
            :type="!passwordShow ? 'password' : 'text'"
            class="passwordinput"
            style="line-height: 20px;"
            v-model="queryPass"
            placeholder="暂无，点击输入"
          />
          <i
            class="iconfont icon-bukanta"
            @click="changePassShow"
            ref="iconChange"
          ></i>
        </p>
      </div>
    </div>
    <div class="bottom-btn">
      <span class="cancel-btn" @click="back">取消</span>
      <span class="save-btn" @click="save">保存</span>
    </div>
    <van-popup v-model="show" position="bottom" :overlay="true">
      <van-picker
        :columns="column"
        :item-height="pickCellheight"
        show-toolbar
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="show2" position="bottom" :overlay="true">
      <van-datetime-picker
        v-model="currentDate"
        :item-height="pickCellheight"
        type="date"
        :min-date="minDate"
        @confirm="onConfirm2"
        @cancel="onCancel2"
      />
    </van-popup>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
export default {
  data() {
    return {
      currentDate: null,
      minDate: new Date(2018, 0, 1),
      acceptDate: '',
      caseId: '15667',
      caseData: {},
      clientType: {},
      column: ['一般授权', '特殊授权'],
      show: false,
      show2: false,
      passwordShow: false,
      queryPass: '',
      dateOld: null,
      loading: false
    }
  },
  mounted() {
    this.loading = true
    this.caseId = this.$route.params.caseId
    this.$axios
      .get(`${caseBase}/lts/inf/queryBasicInf?caseId=${this.caseId}`)
      .then(res => {
        this.caseData = res.data.data
        this.queryPass = this.caseData.queryPass
        this.currentDate = new Date(this.caseData.acceptCaseTime)
        var dataNew = new Date(this.caseData.acceptCaseTime)
        var year = dataNew.getFullYear()
        var month = dataNew.getMonth() + 1
        var day = dataNew.getDate()
        this.acceptDate = year + '-' + month + '-' + day
        if (this.caseData.clientType.length > 4) {
          this.clientType =
            this.caseData.clientType[0] +
            this.caseData.clientType[1] +
            this.caseData.clientType[2] +
            this.caseData.clientType[3]
        }
        this.loading = false
      })
  },
  computed: {
    columns() {
      const column = this.column3
      return [
        {
          values: Object.keys(column),
          className: 'column1'
        },
        {
          values: column[Object.keys(column)[0]],
          className: 'column2',
          defaultIndex: 2
        }
      ]
    },
    pickCellheight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    }
  },
  methods: {
    save() {
      var date
      if (this.dateOld) {
        date = new Date(this.dateOld).getTime()
      } else {
        date = new Date(this.caseData.acceptCaseTime).getTime()
      }
      this.$axios
        .put(caseBase + '/lts/inf/updateOfficesInf', {
          acceptCaseTime: date,
          caseNo: this.caseData.caseNo,
          queryPass: this.queryPass,
          caseId: this.caseId,
          agencyInfId: this.caseData.agencyInfId,
          principal: this.caseData.principal
        })
        .then(res => {
          if (res.data.code === '200') {
            this.$router.back()
          }
        })
    },
    back() {
      this.$router.back()
    },
    select() {
      this.show = true
    },
    onConfirm(value, index) {
      this.clientType = value
      this.show = false
    },
    onCancel() {
      this.show = false
    },
    onConfirm2(value) {
      this.dateOld = value
      var dataNew = value
      var year = dataNew.getFullYear()
      var month = dataNew.getMonth() + 1
      var day = dataNew.getDate()
      this.acceptDate = year + '-' + month + '-' + day
      this.show2 = false
    },
    onCancel2() {
      this.show2 = false
    },
    changePassShow() {
      if (this.passwordShow === true) {
        this.$refs.iconChange.classList.remove('icon-kanguo')
        this.$refs.iconChange.classList.add('icon-bukanta')
        this.passwordShow = !this.passwordShow
      } else {
        this.$refs.iconChange.classList.remove('icon-bukanta')
        this.$refs.iconChange.classList.add('icon-kanguo')
        this.passwordShow = !this.passwordShow
      }
    }
  }
}
</script>
<style scoped>
.bottom-btn {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.bottom-btn > span {
  display: inline-block;
  width: 50%;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: normal;
  color: white;
  background-color: #4b9efb;
  line-height: 50px;
  text-align: center;
}
.cancel-btn {
  background-color: #dddddd !important;
}
.anjian {
  width: 100%;
  height: auto;
}
.anjian-top {
  width: 100%;
  height: 46px;
  line-height: 46px;
  border-color: #eeeeee;
  padding-left: 4%;
  padding-right: 4%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.icon-back {
  font-size: 15px !important;
}
.anjian-top span {
  font-size: 15px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #333333;
  margin-left: 15px;
}
.anjian-content {
  width: 92%;
  height: auto;
  margin: 0 auto;
}
.anjian-content > div {
  width: 100%;
  height: 46px;
  border-color: #eeeeee;
  line-height: 46px;
  display: flex;
  justify-content: space-between;
}
.anjian-content > div > p {
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400 !important;
  color: rgba(102, 102, 102, 1);
}
.iconfont {
  font-size: 25px;
  padding-left: 5px;
}
.anhaoinput {
  width: 60%;
  height: 95%;
  font-size: 15px;
  line-height: 20px;
  border: 0;
  margin-top: 1px;
  text-align: right;
  color: rgba(102, 102, 102, 1);
}
.passContent {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.passwordinput {
  width: 60%;
  height: 95%;
  font-size: 15px;
  line-height: 20px;
  margin-left: 25%;
  margin-top: 1px;
  border: 0;
  text-align: right;
}
</style>
