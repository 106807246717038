<template>
  <fb-page>
    <fb-header slot="header"
               title=""
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <add-index @passData="passData"
               :personData="personData"
               :type="{type,name}"
               :clientLength="$route.params.clientLength"
               @pass="passName"
               @passisTrue="passisTrue"
               @passid="passid"
               @agent="agent"
               @delagent="delagent"
               class='special'></add-index>
    <div class='bottom-btn'>
      <span class='cancel-btn'
            @click="del">删除</span>
      <span class='save-btn'
            @click='save'>保存</span>
    </div>
  </fb-page>
</template>

<script>
// import Qs from 'qs'
import { caseBase } from '~api-config'
import addIndex from '@/pages/CreateCase/components/personItem/index'
// import { agency } from './js/agency'
import { identity } from './js/type'
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      length: 0,
      delindex: null,
      caseId: '',
      type: '',
      name: '',
      personData: '',
      biaoji: true // 添加还是更改
    }
  },
  components: {
    addIndex
  },
  computed: {
    ...mapState('createCase', ['wtrType', 'shouldActiveWtrType'])
  },
  mounted() {
    // console.log(this.$route.params)
  },
  created() {
    // 路由解析
    this.length = this.$route.params.length
    this.caseId = this.$route.params.caseId
    this.type = this.$route.params.type
    this.name = identity(this.type)
    this.personData = this.$route.params.personData
    // 配置参数
    const key = new Date().getTime()
    const ref = this.type + key
    if (!this.personData) {
      this.biaoji = false
    } else {
      this.biaoji = true
    }
    const p = {
      caseId: parseInt(this.caseId),
      refname: ref,
      category: 'NATURAL_PERSON',
      procedureSubject: this.type,
      displayClient: '',
      // 法人或非法人单位名称 或自然人姓名
      name: '',
      sex: 'UNKNOWN',
      nation: '',
      idNumber: '',
      phone: '',
      address: '',
      selfColumnName: '',
      selfColumnValue: '',
      memo: '',
      profession: '',
      workOffice: '',
      // 管理者姓名
      manager: '',
      // 职务
      position: '',
      longResidence: '',
      postalCode: '',
      documentDelivery: '',
      agentList: [],
      weChat: '',
      qq: '',
      age: ''
    }
    if (!this.personData) {
      this.personData = p
    } else {
      this.personData.refname = p.refname
      this.personData.caseId = p.caseId
    }
  },
  watch: {
    // 删除
    delindex() {
      console.log('触发了此次操作', this.delindex, this.personData.agentList)
      if (this.delindex !== null) {
        this.personData.agentList.splice(this.delindex, 1)
      }
    }
  },
  methods: {
    ...mapMutations('createCase', ['setWtrType', 'setShouldActiveWtrType']),
    delagent(index) {
      this.delindex = index
    },
    del() {
      // 删除
      if (!this.personData) {
        this.$router.back()
      } else {
        if (this.length > 1) {
          var id = this.personData.litigantId
          this.$axios
            .delete(
              `${caseBase}/lts/caseLitigant/deleteLitigant?caseId=${this.caseId}&litigantId=${id}`
            )
            .then(res => {
              if (res.data.code === '200') {
                this.$router.back()
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.$notify('当前数据为最后一条，不能删除，请先添加！')
        }
      }
    },
    agent(data) {
      this.personData.agentList = data.agentList
    },
    passid(data) {
      this.personData.category = data
    },
    passisTrue(bool) {
      if (bool === true) {
        this.personData.displayClient = 'YES'
      } else {
        this.personData.displayClient = 'NO'
      }
    },
    save() {
      // console.log(this.wtrType)
      // if (this.wtrType && this.wtrType) {
      // } else {
      //   this.$notify('请设置委托人')
      //   return false
      // }
      this.personData.agentList.forEach(element => {
        if (element.agencyType === '律师') {
          element.agencyType = 'LAWYER'
        }
      })
      var nameExit = Boolean
      this.personData.agentList.forEach(item => {
        if (item.name !== null) {
          nameExit = true
        } else {
          nameExit = false
        }
      })
      // 保存之前判断是否曾经保存过律师+律所
      if (this.biaoji === false) {
        if (this.personData.name !== '') {
          if (nameExit) {
            if (this.personData.agentList.length > 2) {
              this.$notify('您最多能添加2个代理人')
              return false
            } else if (this.personData.agentList.length < 2) {
              this.$axios
                .post(
                  `${caseBase}/lts/litigant/addLitigant?caseId=${this.caseId}`,
                  this.personData
                )
                .then(res => {
                  // this.personData.agentList[0].lawFirmName
                  // this.personData.agentList[1].lawFirmName
                  // this.personData.agentList.procedureSubject === 'PLAINTIFF'
                })
                .catch(err => {
                  console.log(err)
                })
              this.$router.back()
            } else {
              if (
                this.personData.agentList[0].name ===
                this.personData.agentList[1].name
              ) {
                this.$notify('您已重复添加相同律师')
              } else {
                this.$axios
                  .post(
                    `${caseBase}/lts/litigant/addLitigant?caseId=${this.caseId}`,
                    this.personData
                  )
                  .then(res => {
                    // this.personData.agentList[0].lawFirmName
                    // this.personData.agentList[1].lawFirmName
                    // this.personData.agentList.procedureSubject === 'PLAINTIFF'
                  })
                  .catch(err => {
                    console.log(err)
                  })
                this.$router.back()
              }
            }
          } else {
            this.$notify('代理人姓名不能为空，请输入！')
          }
        } else {
          this.$notify('姓名/名称不能为空，请输入！')
        }
      } else {
        this.personData.isShowLigitantInfo = true
        this.personData.arrowDirection = 'icon-child-on'
        if (this.personData.name !== '') {
          if (nameExit) {
            if (this.personData.agentList.length > 2) {
              this.$notify('您最多能添加2个代理人')
            } else if (this.personData.agentList.length < 2) {
              this.$axios
                .put(
                  `${caseBase}/lts/litigant/updateLitigant?caseId=${this.caseId}`,
                  this.personData
                )
                .then(res => {
                  // this.personData.agentList[0].lawFirmName
                  // this.personData.agentList[1].lawFirmName
                  // this.personData.agentList.procedureSubject === 'PLAINTIFF'
                })
                .catch(err => {
                  console.log(err)
                })
              this.$router.back()
            } else {
              if (
                this.personData.agentList[0].name ===
                this.personData.agentList[1].name
              ) {
                this.$notify('您已重复添加相同律师')
              } else {
                this.$axios
                  .put(
                    `${caseBase}/lts/litigant/updateLitigant?caseId=${this.caseId}`,
                    this.personData
                  )
                  .then(res => {
                    // this.personData.agentList[0].lawFirmName
                    // this.personData.agentList[1].lawFirmName
                    // this.personData.agentList.procedureSubject === 'PLAINTIFF'
                  })
                  .catch(err => {
                    console.log(err)
                  })
                this.$router.back()
              }
            }
          } else {
            this.$notify('代理人姓名不能为空，请输入！')
          }
        } else {
          this.$notify('姓名/名称不能为空，请输入！')
        }
      }
    },
    passData(data) {
      for (var key1 in this.personData) {
        for (var key2 in data) {
          if (key1 === key2) {
            this.personData[key1] = data[key2]
          }
        }
      }
    },
    passName(name) {
      this.personData.name = name
    },
    back() {
      this.$router.back()
    }
  },
  destroyed() {}
}
</script>
<style lang='stylus'>
.special
  margin-top 0px !important
  padding-bottom 50px !important
.person-form-item
  padding-bottom 5px
.bottom-btn
  display flex
  justify-content space-between
  position fixed
  bottom 0
  width 100%
  span
    display inline-block
    width 50%
    height 50px
    font-size 16px
    font-family PingFangSC
    font-weight normal
    color white
    background-color #4B9EFB
    line-height 50px
    text-align center
  .cancel-btn
    background-color #DDDDDD
.top-nav
  width 100%
  height 46px
  line-height 46px
  border-color rgba(239, 239, 239, 1)
  font-size 20px
  padding-left 10px
  position fixed
  top 0
  background-color white
  z-index 200
.title-with-switch
  display flex
  align-items center
  position relative
  .name-input
    width 100%
    margin 0 auto
  .name-input-clear
    color #999
    font-size 16px
    line-height 18px
    position absolute
.search-suggestion
  position absolute
  width calc(90% - 55px)
  background #ffffff
  max-height 240px
  overflow-y auto
  box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
  border-radius 0 0 4px 4px
  z-index 10
  left calc(21%)
  top 83px
  .van-loading
    margin 0 auto
  .suggestion-list-item
    display flex
    justify-content space-between
    align-items center
    padding 8px 18px
    .name
      font-size 14px
      color #555
    .attr
      font-size 13px
      color #888
    .attr.tag
      background #3EB3F0
      color #fff
      padding 0 2px
      border-radius 2px
.person-item
  margin-top 46px
.collapse-item__wrapper
  transition height 0.3s ease-in-out
  will-change height
  overflow-y auto
  background #F0F6FD
  height auto !important
  .collapse-item__content
    padding 0 0.2rem 0.2rem 0.2rem
    background white
    .id-type
      .van-collapse
        .van-collapse-item
          .van-collapse-item__title
            background transparent
.id-type
  .van-collapse
    .id-type-cell.van-collapse-item
      .van-cell
        line-height 20px !important
        border-bottom 0.026667rem solid #d2d2d2
        .van-cell__value
          span
            color #666
  .id-type-radio-group
    display flex
    padding 5px 0
    justify-content space-between
    align-items center
    .van-radio
      .van-radio__input
        font-size 12px
        height 12px
        line-height 14px
        padding-left 2px
        box-sizing border-box
    .van-radio .van-icon-checked
      color #2781FD
.create-case-page
  background #EEF7FC
.create-case-wrapper
  overflow-y auto
  position relative
.create-case-form
  background #EEF7FC
  padding-top 10px
  height 100%
  .basic-section
    background #fff
    margin 0px 10px 5px 10px
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    border-radius 4px
    .reason-wrapper
      display flex
      align-items center
      .reason-item
        flex 1
      .use-ocr
        width 28px
        i
          font-size 22px
          color #569AF9
  .add-other-type-person
    padding 10px 20px
    font-size 14px
    text-align right
    color #569AF9
    i
      font-size 14px
  .yuangao-section, .beigao-section, .basic-section, .person-section
    margin 5px 10px
    background #ffffff
    border-radius 4px
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    .sec
      padding 10px
      display flex
      justify-content space-between
      align-items center
      .title
        font-size 14px
        padding-left 5px
        color #333
      .action
        text-decoration none
        color #569AF9
        font-size 14px
        i
          font-size 14px
    .shenlijibie
      border-radius 0 0 4px 4px
      .van-collapse-item
        border-radius 0 0 4px 4px
        .van-cell
          border-radius 0 0 4px 4px
          .van-cell__value
            color #666
      .van-collapse-item__wrapper
        border-radius 0 0 4px 4px
        background #F0F6FD
        .van-collapse-item__content
          .shenji-radio-group, .chengxu-radio-group
            display flex
            padding 5px 0
            justify-content space-between
            align-items center
            .van-radio
              .van-radio__input
                font-size 13px
                line-height 14px
                padding-left 2px
                box-sizing border-box
            .van-radio .van-icon-checked
              color #2781FD
          .shenji-radio-group
            border-bottom 0.026667rem solid #ebebeb
            margin-bottom 10px
            padding-bottom 10px
  .create-case-btn
    display flex
    justify-content center
    .btn
      border-radius 2px
      background #569AF9
      color #fefefe
      padding 4px 20px
      font-size 16px
      margin 20px 0 200px 0
.title-with-switch
  display flex
  align-items center
  justify-content space-between
  .input
    flex 1
    margin-left 10px
    input
      text-align right
.person-info-cell-field.van-cell.van-field, .text-right
  .van-cell__value
    .van-field__body
      .van-field__control
        text-align right
.person-list
  border-radius 0 0 4px 4px
  .person-item
    .person-swiper-cell.van-swipe-cell
      z-index 2
      .van-swipe-cell__wrapper
        .van-swipe-cell__right
          color #fff
          font-size 15px
          width 65px
          height 44px
          display inline-block
          text-align center
          line-height 44px
          background-color #f44
          box-sizing border-box
          border none
  .person-item:nth-last-child(1)
    margin-bottom 8px
    padding-bottom 8px
    border-radius 0 0 4px 4px
.person-info-cell.van-collapse
  margin-top -42px
  z-index 1
.form-wrapper
  padding 0.266667rem 0
  margin 0 0 0 0.4rem
  position relative
  box-sizing border-box
  width clac(100% - 10px)
  &::after
    content ''
    position absolute
    pointer-events none
    box-sizing border-box
    left 0rem
    bottom 0
    width 100%
    transform scaleY(0.5)
    border-bottom 0.026667rem solid #d2d2d2
  .form-item
    display flex
    align-items center
    .label
      font-size 14px
      color #333
      justify-content space-between
    .van-radio-group
      display flex
      flex 1
      justify-content flex-end
      align-items center
      .van-radio
        margin 0 10px
        .van-radio__input
          font-size 13px
          line-height 14px
          padding-left 2px
          box-sizing border-box
      .van-radio .van-icon-checked
        color #2781FD
.van-cell
  line-height 23px !important
.person-swiper-cell
  .van-swipe-cell__wrapper
    .van-cell
      .van-icon
        display none
.collapse-item__wrapper
  .collapse-item__content
    .person-form-item
      .agent
        .agent-list
          .agent-item-wrapper
            .agent-pop
              .van-cell-group
                .search-suggestion
                  position absolute
                  left 28%
                  width 70%
                  top 75px
                  background #ffffff
                  max-height 240px
                  overflow-y auto
                  box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
                  border-radius 0 0 4px 4px
                  z-index 10
</style>
