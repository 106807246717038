var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: {
            slot: "header",
            title: _vm.caseData.reasonRelation,
            fixed: "",
          },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c("div", { staticClass: "anjian-content" }, [
        _vm.loading
          ? _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [_c("van-loading")],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("案由")]),
          _c("p", [_vm._v(_vm._s(_vm.caseData.reasonRelation))]),
        ]),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("代理日期")]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.acceptDate) + " "),
            _c("i", {
              staticClass: "iconfont icon-calendar",
              on: {
                click: function ($event) {
                  _vm.show2 = !_vm.show2
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("代理身份")]),
          _c("p", [_vm._v(_vm._s(_vm.caseData.principal))]),
        ]),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("代理权限")]),
          _c("p", { on: { click: _vm.select } }, [
            _vm._v(_vm._s(_vm.clientType)),
          ]),
        ]),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("案号")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.caseData.caseNo,
                expression: "caseData.caseNo",
              },
            ],
            staticClass: "anhaoinput",
            staticStyle: { "line-height": "20px" },
            attrs: { type: "text", placeholder: "暂无，点击输入" },
            domProps: { value: _vm.caseData.caseNo },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.caseData, "caseNo", $event.target.value)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "border-bottom" }, [
          _c("p", [_vm._v("密码")]),
          _c("p", { staticClass: "passContent" }, [
            (!_vm.passwordShow ? "password" : "text") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.queryPass,
                      expression: "queryPass",
                    },
                  ],
                  staticClass: "passwordinput",
                  staticStyle: { "line-height": "20px" },
                  attrs: { placeholder: "暂无，点击输入", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.queryPass)
                      ? _vm._i(_vm.queryPass, null) > -1
                      : _vm.queryPass,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.queryPass,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.queryPass = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.queryPass = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.queryPass = $$c
                      }
                    },
                  },
                })
              : (!_vm.passwordShow ? "password" : "text") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.queryPass,
                      expression: "queryPass",
                    },
                  ],
                  staticClass: "passwordinput",
                  staticStyle: { "line-height": "20px" },
                  attrs: { placeholder: "暂无，点击输入", type: "radio" },
                  domProps: { checked: _vm._q(_vm.queryPass, null) },
                  on: {
                    change: function ($event) {
                      _vm.queryPass = null
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.queryPass,
                      expression: "queryPass",
                    },
                  ],
                  staticClass: "passwordinput",
                  staticStyle: { "line-height": "20px" },
                  attrs: {
                    placeholder: "暂无，点击输入",
                    type: !_vm.passwordShow ? "password" : "text",
                  },
                  domProps: { value: _vm.queryPass },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.queryPass = $event.target.value
                    },
                  },
                }),
            _c("i", {
              ref: "iconChange",
              staticClass: "iconfont icon-bukanta",
              on: { click: _vm.changePassShow },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "bottom-btn" }, [
        _c("span", { staticClass: "cancel-btn", on: { click: _vm.back } }, [
          _vm._v("取消"),
        ]),
        _c("span", { staticClass: "save-btn", on: { click: _vm.save } }, [
          _vm._v("保存"),
        ]),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: true },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.column,
              "item-height": _vm.pickCellheight,
              "show-toolbar": "",
            },
            on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", overlay: true },
          model: {
            value: _vm.show2,
            callback: function ($$v) {
              _vm.show2 = $$v
            },
            expression: "show2",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              "item-height": _vm.pickCellheight,
              type: "date",
              "min-date": _vm.minDate,
            },
            on: { confirm: _vm.onConfirm2, cancel: _vm.onCancel2 },
            model: {
              value: _vm.currentDate,
              callback: function ($$v) {
                _vm.currentDate = $$v
              },
              expression: "currentDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }